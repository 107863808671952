(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name championship.assignTeams
   * @description
   *
   */
  angular
      .module('neo.home.championships.championship.assignTeams', [
        'ui.router',
        'dndLists'
      ]);
}());
